<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Asistencias</v-tab>
      <v-tab>Calificaciónes</v-tab>
    </v-tabs>

    <v-card flat>
      <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="onSubmit">
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="mt-1">
                <v-col cols="4">
                  <v-combobox
                    label="Día de Clase"
                    v-model="daySelected"
                    @change="setStudentsDaySelected"
                    :items="classDays"
                    item-text="start"
                    item-value="_id"
                    menu-props="auto"
                    hide-details
                  >
                    <template #selection="data">
                      {{ date(data.item.start).format('DD/MM/YYYY') }} {{ date(data.item.start).format('HH:mm') }} -
                      {{ date(data.item.end).format('HH:mm') }}
                    </template>
                    <template #item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ date(data.item.start).format('DD/MM/YYYY') }}
                            {{ date(data.item.start).format('HH:mm') }} - {{ date(data.item.end).format('HH:mm') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="1">
                  <v-btn class="mt-4 mb-2" fab color="primary" small @click="classDaysDialog = true">
                    <v-icon>
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>

                  <!-- dialog -->
                  <v-dialog v-model="classDaysDialog" max-width="1000px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Días de Clase</span>
                      </v-card-title>
                      <v-card-text>
                        <class-days v-model="editClassDays" @input="onUpdateClassDays"></class-days>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col v-if="daySelected" cols="7">
                  <v-textarea
                    v-model="daySelected.observation"
                    label="Observación del día"
                    auto-grow
                    outlined
                    rows="1"
                    class="mt-2"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" v-if="daySelected">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Alumno</th>
                          <th>Asistencia</th>
                          <th>Observaciónes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <v-switch
                              v-model="checkAll"
                              color="success"
                              label="Marcar todos"
                              @change="markAll"
                              hide-details
                            ></v-switch>
                          </td>
                          <td></td>
                        </tr>
                        <tr v-for="(student, index) in students" :key="index">
                          <td>{{ student.studentData.name }} {{ student.studentData.lastname }}</td>
                          <td>
                            <v-switch v-model="student[daySelected._id].status" color="success" hide-details></v-switch>
                          </td>
                          <td>
                            <v-textarea
                              v-model="student[daySelected._id].observation"
                              auto-grow
                              outlined
                              rows="1"
                              row-height="15"
                              class="mt-2"
                            ></v-textarea>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="mt-1">
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Alumno</th>
                          <th>Calificación</th>
                          <th>Observaciónes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="student in students" :key="student._id">
                          <td>
                            <div class="font-weight-bold">
                              {{ student.studentData.name }} {{ student.studentData.lastname }}
                            </div>
                          </td>
                          <td style="width: 120px" v-if="subject.qualificationType == 'course'">
                            <v-text-field
                              v-model.number="student.qualy"
                              type="number"
                              class="mt-2"
                              outlined
                              :rules="[validators.qualyValidator]"
                            ></v-text-field>
                          </td>
                          <td style="width: 200px" v-else-if="subject.qualificationType == 'workshop'">
                            <v-select
                              :items="$store.state['app-group'].workshopQualy"
                              v-model="student.qualy"
                              item-text="title"
                              item-value="value"
                              hide-details
                            ></v-select>
                          </td>
                          <td>
                            <v-textarea
                              v-model="student.qualyObs"
                              outlined
                              rows="2"
                              row-height="15"
                              class="mt-2"
                            ></v-textarea>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" type="submit" :loading="buttonLoading" :disabled="buttonLoading"> Guardar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { qualyValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiPencilOutline } from '@mdi/js'
import { inject, ref, watch } from 'vue'

import ClassDays from '@/views/apps/group/group-view/group-panel/Subjects/ClassDays.vue'
import UsersSelection from '@/views/components/UsersSelection.vue'

export default {
  components: {
    UsersSelection,
    ClassDays,
  },
  props: {
    subjectData: {
      type: Object,
      required: true,
    },
    groupData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const subject = ref(JSON.parse(JSON.stringify(props.subjectData)))
    const date = inject('date')
    const classDays = ref([])
    const editClassDays = ref([])
    const students = ref(JSON.parse(JSON.stringify(props.groupData.students)))
    const daySelected = ref()
    const userList = ref([])
    const classDaysDialog = ref(false)
    const checkAll = ref(false)

    const tab = ref('')

    const valid = ref(false)
    const form = ref(null)
    const buttonLoading = ref(false)

    const getStudentsQualy = () => {
      students.value.forEach(student => {
        let currentQualy = student.qualifications.find(qualy => qualy.subject == subject.value._id)

        if (currentQualy) {
          student.qualy = currentQualy.qualification
          student.qualyObs = currentQualy.observation
        } else {
          student.qualy = null
          student.qualyObs = ''
        }
      })
    }

    const setStudentsDaySelected = () => {
      if (daySelected.value && daySelected.value._id) {
        students.value.forEach(student => {
          let currentAttendance = student.attendances.find(attendance => attendance.classDay == daySelected.value._id)

          if (currentAttendance)
            student[daySelected.value._id] = {
              classDay: daySelected.value._id,
              status: currentAttendance.status,
              observation: currentAttendance.observation,
            }
          else
            student[daySelected.value._id] = {
              classDay: daySelected.value._id,
              status: false,
              observation: '',
            }
        })

        checkAll.value = students.value.every(student => student[daySelected.value._id].status == true) ? true : false
      }
    }

    const setDay = () => {
      //Order desc classDays list
      classDays.value = subject.value.classDays.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start)
      })
      editClassDays.value = JSON.parse(
        JSON.stringify(
          subject.value.classDays.sort(function (a, b) {
            return new Date(a.start) - new Date(b.start)
          }),
        ),
      )
      //Auto select today class day
      daySelected.value = classDays.value
        .filter(classDay => {
          if (date(classDay.start).format('DD/MM/YYYY') == date().format('DD/MM/YYYY'))
            return date(classDay.start) >= date()
        })
        .at(0)
    }

    setDay()
    setStudentsDaySelected()
    getStudentsQualy()

    const validate = () => {
      form.value.validate()
    }

    // on form submit
    const onSubmit = async () => {
      await setStudentClassDay()
      await setStudentQualy()
      if (valid.value) {
        buttonLoading.value = true

        const studentsToSave = JSON.parse(JSON.stringify(students.value))
        const studentsBkp = JSON.parse(JSON.stringify(props.groupData.students))

        studentsToSave.forEach(student => {
          if (daySelected.value) {
            student.attendances = student.attendances.filter(att => att.classDay == daySelected.value._id)
          } else delete student.attendances
          student.qualifications = student.qualifications.filter(
            qualy => qualy.subject == subject.value._id && (qualy.qualification !== null || qualy.observation),
          )
        })

        studentsBkp.forEach(student => {
          if (daySelected.value) {
            student.attendances = student.attendances.filter(att => att.classDay == daySelected.value._id)
          } else delete student.attendances
          student.qualifications = student.qualifications.filter(
            qualy => qualy.subject == subject.value._id && (qualy.qualification !== null || qualy.observation),
          )
        })

        store
          .dispatch('app-group/updateGroupStudents', {
            params: {
              students: studentsToSave,
              studentsBkp,
              classDay: daySelected.value,
              subject: subject.value,
            },
            group: props.groupData._id,
          })
          .then(r => {
            buttonLoading.value = false
            daySelected.value = null
            emit('reloadGroups')
            snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
          })
          .catch(e => {
            buttonLoading.value = false
            snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
          })
      } else {
        validate()
      }
    }

    const onUpdateClassDays = claDys => {
      store
        .dispatch('app-group/updateClassDays', {
          params: { subject: subject.value._id, classDays: claDys, bkpClassDays: classDays.value },
          group: props.groupData._id,
        })
        .then(r => {
          emit('reloadGroups')
          snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
        })
        .catch(e => {
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    const setStudentClassDay = async () => {
      if (daySelected.value && daySelected.value._id) {
        students.value.forEach(student => {
          let attIndx = student.attendances.findIndex(att => att.classDay == daySelected.value._id)

          if (attIndx > -1) {
            student.attendances[attIndx].status = student[daySelected.value._id].status
            student.attendances[attIndx].observation = student[daySelected.value._id].observation
          } else {
            student.attendances.push({
              classDay: daySelected.value._id,
              status: student[daySelected.value._id].status,
              observation: student[daySelected.value._id].observation,
            })
          }
        })
      }
    }

    const setStudentQualy = async () => {
      students.value.forEach(student => {
        let qualyIndx = student.qualifications.findIndex(qualy => qualy.subject == subject.value._id)

        if (qualyIndx > -1) {
          student.qualifications[qualyIndx].qualification = student.qualy
          student.qualifications[qualyIndx].observation = student.qualyObs
        } else {
          student.qualifications.push({
            subject: subject.value._id,
            qualification: student.qualy,
            observation: student.qualyObs,
          })
        }
      })
    }

    const markAll = status => {
      let studentsCopy = students.value
      students.value = {}
      studentsCopy.map(student => (student[daySelected.value._id].status = status))
      students.value = studentsCopy
    }

    watch(
      () => props.subjectData,
      value => {
        subject.value = JSON.parse(JSON.stringify(value))
        setDay()
        getStudentsQualy()
      },
    )

    watch(
      () => props.groupData,
      value => {
        students.value = JSON.parse(JSON.stringify(props.groupData.students))
        getStudentsQualy()
      },
    )

    return {
      checkAll,
      students,
      subject,
      form,
      tab,
      onSubmit,
      valid,
      validate,
      validators: { required, qualyValidator },
      buttonLoading,
      date,
      daySelected,
      students,
      classDays,
      setStudentsDaySelected,
      classDaysDialog,
      editClassDays,
      onUpdateClassDays,
      markAll,

      // icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
